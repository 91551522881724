import { AccountInfo } from "@azure/msal-browser";
import React from "react";
import { Route } from "src/routes";
import { Layout } from "src/utils/constants";
import { IUserConfig } from "src/utils/interfaces";

export type ConfigContextProps = {
  siteLayout: Layout,
}

export const ConfigContext = React.createContext<ConfigContextProps>({
  siteLayout: Layout.TELESTE,
});

type AppContextProps = {
  currentUser?: AccountInfo,
  userConfig?: IUserConfig,
  currentRoute?: Route,
}

export const AppContext = React.createContext<AppContextProps>({
  currentUser: undefined,
  userConfig: undefined,
  currentRoute: undefined,
});

type FormFieldsContextProps = {
  disabled: boolean,
}

export const FormFieldsContext = React.createContext<FormFieldsContextProps>({
  disabled: false,
});

type FormStateContextProps = {
  requiredFields: string[],
}

export const FormStateContext = React.createContext<FormStateContextProps>({
  requiredFields: [],
});