export const colors = {
  skyBlue: "#6EB4E1",
  blue: "#037ac9",
  darkBlue: "#03385C",
  darkerBlue: "#03223a",
  red: "#cd2e23",
  darkRed: "#ae271e",
  brightYellow: "#F5CD23",
  darkYellow: "#d6b10a",
  green: "#2e8321",
  darkGreen: "#1e5516",

  transparentDarkBlue: "rgba(3, 56, 92, 0.08)",
  transparentRed: "rgba(205, 46, 35, 0.08)",
  transparentYellow: "rgba(245, 205, 35, 0.08)",

  white: "#ffffff",
  lightestGray: "#FAFAFA",
  lighterGray: "#DDDDDD",
  lightGray: "#D3D3D3",
  gray: "#999999",
  darkGray: "#6e6e6e",
  darkerGray: "#4a4a4a",
  darkestGray: "#1e1e1e",
  black: "#000000",
};