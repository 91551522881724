import React from "react";
import { DualRing as Spinner } from "react-css-spinners";
import { colors } from "src/utils/colors";
import styled from "styled-components";

type LoadingSpinnerProps = {
  color?: string,
  size?: number,
  margins?: number,
}

/** A general use loading spinner. Change the import from "DualRing" to a different supported spinner to change the look. */
export const LoadingSpinner = ({ color = colors.darkestGray, size, margins }: LoadingSpinnerProps) => {
  return (
    <SpinnerContainer margins={margins}>
      <Spinner color={color} size={size} />
    </SpinnerContainer>
  );
};

type SpinnerContainerProps = {
  margins?: number,
}

const SpinnerContainer = styled.div<SpinnerContainerProps>`
  display: flex;
  justify-content: center;
  margin: ${({ margins }) => `${margins ?? 15}px 0`};
`;
