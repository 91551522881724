import { AccountInfo, AuthenticationResult, Configuration, EventMessage, EventType, PublicClientApplication, RedirectRequest } from "@azure/msal-browser";
import { IConfig } from "src/utils/interfaces";

let appConfig: IConfig;
let msalConfig: Configuration;

export const getMsalConfig = (config: IConfig): Configuration => {
  appConfig = config;
  msalConfig = {
    auth: {
      clientId: config.clientId ?? "",
      authority: `${config.instance}/${config.domain}/${config.signUpSignInPolicyId}`,
      knownAuthorities: config.instance ? [config.instance.replace("https://", "")] : undefined,
      redirectUri: window.location.origin,
      postLogoutRedirectUri: window.location.origin,
    },
    cache: {
      cacheLocation: "localStorage",
    },
  };
  return msalConfig;
};

let loginRequest: RedirectRequest = {
  scopes: [],
};

export const getLoginRequest = (config: IConfig = appConfig): RedirectRequest => {
  loginRequest = {
    scopes: ["openid", "email", config.clientId ?? ""],
  };
  return loginRequest;
};

let msalInstance: PublicClientApplication;

export const getSUSIAccount = (accounts: AccountInfo[]) => {
  return accounts.find(account => (account.idTokenClaims as any).tfp === appConfig.signUpSignInPolicyId) ?? accounts[0];
};

export const setupMsal = (config: Configuration): PublicClientApplication => {
  msalInstance = new PublicClientApplication(config);
  const accounts = msalInstance.getAllAccounts();
  if (accounts.length > 0) {
    msalInstance.setActiveAccount(getSUSIAccount(accounts));
  }
  msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const payload = event.payload as AuthenticationResult;
      const account = payload.account;
      msalInstance.setActiveAccount(account);
    }
  });

  return msalInstance;
};

export const getMsalAccessToken = async (): Promise<string | void> => {
  const accounts = msalInstance.getAllAccounts();
  const request = {
    ...loginRequest,
    account: getSUSIAccount(accounts),
  };

  try {
    const response = await msalInstance.acquireTokenSilent(request);
    return response.accessToken;
  } catch (e) {
    await msalInstance.acquireTokenRedirect(request);
  }
};

export const msalLogin = async (): Promise<AuthenticationResult | void> => {
  try {
    return await msalInstance.loginRedirect(loginRequest);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log("Login failed", e);
  }
};

export const msalLogout = async (): Promise<void> => {
  try {
    return await msalInstance.logoutRedirect();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log("Logout failed", e);
  }
};

export const msalEditProfile = async (): Promise<void> => {
  try {
    return await msalInstance.loginRedirect({ ...loginRequest, authority: `${appConfig.instance}/${appConfig.domain}/${appConfig.editProfilePolicyId}` });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log("Profile edit call failed", e);
  }
};