import { InteractionType, PublicClientApplication, RedirectRequest } from "@azure/msal-browser";
import { IMsalContext, MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react";
import { GTMProvider } from "@elgorditosalsero/react-gtm-hook";
import { ISnippetsParams } from "@elgorditosalsero/react-gtm-hook/dist/models/GoogleTagManager";
import React, { useEffect, useState } from "react";
import { getLoginRequest, getMsalConfig, setupMsal } from "src/auth";
import { LoadingSpinner } from "src/components/LoadingSpinner";
import { MsalError } from "src/components/MsalError";
import { IConfig } from "src/utils/interfaces";
import { RestRequest } from "src/utils/RestRequest";
import { Layout } from "../utils/constants";
import { ConfigContext, ConfigContextProps } from "../utils/context";

type MsalInitializerProps = {
  children: React.ReactNode;
}

export const MsalHandler = ({ children }: MsalInitializerProps) => {
  const [msalInstance, setMsalInstance] = useState<PublicClientApplication>();
  const [loginRequest, setLoginRequest] = useState<RedirectRequest>();
  const [configContext, setConfigContext] = useState<ConfigContextProps>({ siteLayout: Layout.TELESTE });
  const [gtmParams, setGTMParams] = useState<ISnippetsParams>({ id: "" });

  useEffect(() => {
    new RestRequest<IConfig>("api/config", { noToken: true }).get()
      .then(response => {
        if (response) {
          setMsalInstance(setupMsal(getMsalConfig(response)));
          setLoginRequest(getLoginRequest(response));
          setConfigContext({ siteLayout: response.siteLayout || Layout.TELESTE });
          setGTMParams({ id: response.gtmId });
        }
      });
  }, []);

  return (msalInstance && loginRequest) ? (
    <MsalProvider instance={msalInstance}>
      <GTMProvider state={gtmParams}>
        <MsalAuthenticationTemplate
          interactionType={InteractionType.Redirect}
          authenticationRequest={loginRequest}
          loadingComponent={LoadingSpinner as React.ElementType<IMsalContext>}
          errorComponent={() => <MsalError />}
        >
          <ConfigContext.Provider value={configContext}>
            {children}
          </ConfigContext.Provider>
        </MsalAuthenticationTemplate>
      </GTMProvider>
    </MsalProvider>
  ) : null;
};