import axios, { AxiosError } from "axios";
import download from "downloadjs";
import { toast } from "react-toastify";
import { getMsalAccessToken } from "src/auth";
import { IFile } from "src/utils/interfaces";

type RestRequestParams = Record<string, string>;

type RestRequestHeaders = {
  "Cache-Control"?: "no-cache",
  "Content-Type"?: "application/json",
}

type RestRequestOptions = {
  showErrors: boolean,
  errorMessage: string,
  enableCache: boolean,
  noToken: boolean,
}

export enum HttpStatus {
  OK = 200,
  CREATED = 201,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  INTERNAL_SERVER_ERROR = 500,
}

/**
 * Class for making REST requests
 *
 * @example const result = await new RestRequest<ReceivedDataType>("rest/receivedata").get();
 */
export class RestRequest<DataType, ReturnType = DataType> {
  private readonly endpoint: string;
  private readonly showErrors: boolean;
  private readonly enableCache: boolean;
  private readonly errorMessage: string | undefined;
  private readonly noToken: boolean;

  /**
   * Create a RestRequest object
   *
   * @param {string} endpoint
   * @param {object} [options]
   */
  constructor(endpoint: string, options?: Partial<RestRequestOptions>) {
    this.endpoint = endpoint;
    this.showErrors = options?.showErrors ?? true;
    this.enableCache = options?.enableCache ?? false;
    this.errorMessage = options?.errorMessage;
    this.noToken = options?.noToken ?? false;
  }

  /**
   * Make a GET request
   *
   * @param {string|number} [id]
   * @param {RestRequestParams} [params]
   * @returns {Promise} A promise that resolves in the expected data type or null
   */
  public async get(id?: string | number, params?: RestRequestParams): Promise<ReturnType | null> {
    const fetchURI = this.getRequestURI(id, params);
    const headers = await this.getRequestHeaders({
      "Cache-Control": this.enableCache ? undefined : "no-cache"
    });

    try {
      const response = await axios.get<ReturnType>(fetchURI, {
        headers,
      });
      if (response?.status === HttpStatus.OK) {
        return response.data;
      } else {
        this.showError(`GET request failed with status code ${response?.status}`);
        return null;
      }
    } catch (error: any) {
      return this.handleError(error);
    }
  }

  /**
   * Make a GET request with query parameters
   *
   * @param {RestRequestParams} params
   * @returns {Promise} A promise that resolves in the expected data type or null
   */
  public async getQuery(params: RestRequestParams): Promise<ReturnType | null> {
    return this.get(undefined, params);
  }

  /**
   * Make a POST request
   *
   * @param {DataType} data
   * @returns {Promise} A promise that resolves in the expected data type or null
   * @template DataType
   */
  public async post(data: DataType): Promise<ReturnType | null> {
    const fetchURI = this.getRequestURI();
    const headers = await this.getRequestHeaders({
      "Content-Type": "application/json",
    });

    try {
      const response = await axios.post<ReturnType>(fetchURI, data, {
        headers,
      });
      if (response?.status === HttpStatus.OK || response?.status === HttpStatus.CREATED) {
        return response.data;
      } else {
        this.showError(`POST request failed with status code ${response?.status}`);
        return null;
      }
    } catch (error: any) {
      return this.handleError(error);
    }
  }

  /**
   * Make a PUT request
   *
   * @param {string|number} id
   * @param {DataType} data
   * @returns {Promise} A promise that resolves in the expected data type or null
   * @template DataType
   */
  public async put(id: string | number, data: DataType): Promise<ReturnType | null> {
    const fetchURI = this.getRequestURI(id);
    const headers = await this.getRequestHeaders({
      "Content-Type": "application/json",
    });

    try {
      const response = await axios.put<ReturnType>(fetchURI, data, {
        headers,
      });
      if (response?.status === HttpStatus.OK) {
        return response.data;
      } else {
        this.showError(`PUT request failed with status code ${response?.status}`);
        return null;
      }
    } catch (error: any) {
      return this.handleError(error);
    }
  }

  /**
   * Make a DELETE request
   *
   * @param {string} id
   * @returns {Promise} A promise that resolves in a boolean true on successful DELETE
   * and false if unsuccessful
   */
  public async delete(id: string | number): Promise<boolean> {
    const fetchURI = this.getRequestURI(id);
    const headers = await this.getRequestHeaders();

    try {
      const response = await axios.delete<ReturnType>(fetchURI, {
        headers,
      });
      if (response?.status === HttpStatus.OK) {
        return true;
      } else {
        this.showError(`DELETE request failed with status code ${response?.status}`);
        return false;
      }
    } catch (error: any) {
      return this.handleError(error, false) ?? false;
    }
  }

  /**
   * Download a file
   *
   * @param {IFile} [file]
   * @param {RestRequestParams} [params]
   */
  public async download(file: IFile, params?: RestRequestParams): Promise<void> {
    const fetchURI = this.getRequestURI(file.id, params);
    const headers = await this.getRequestHeaders();

    try {
      const response = await axios({
        url: fetchURI,
        method: 'GET',
        responseType: 'blob',
        headers,
      });
      if (response?.status === HttpStatus.OK) {
        download(response.data, file.name, file.type);
        return;
      } else {
        this.showError(`File download failed with status code ${response?.status}`);
        return;
      }
    } catch (error: any) {
      this.handleError(error);
      return;
    }
  }

  private handleError<T = null>(error: AxiosError, returnValue?: T): T | null {
    this.showError(this.errorMessage ?? error.message);
    return returnValue ?? null;
  }

  private showError(error: string): void {
    if (this.showErrors) {
      toast.error(error);
    }
  }

  private getRequestURI(id?: string | number, params?: RestRequestParams): string {
    let endpoint = this.endpoint;
    if (id !== undefined) {
      endpoint = this.endpoint.replace("{id}", id.toString());
      if (endpoint === this.endpoint) {
        endpoint = `${this.endpoint}/${id}`;
      }
    }
    const queryParams = new URLSearchParams(params).toString();
    const paramString = queryParams ? `?${queryParams}` : '';

    return `/${endpoint}${paramString}`;
  }

  private async getRequestHeaders(additionalHeaders?: RestRequestHeaders): Promise<Record<string, any>> {
    const headers: Record<string, any> = { ...additionalHeaders };
    if (!this.noToken) {
      const token = await getMsalAccessToken();
      headers["Authorization"] = `Bearer ${token}`;
    }
    return headers;
  }
}
